<template>
  <!-- 设备报警列表 -->
  <div class="newSeeds roadNetwork">
    <div class="title">
      <div class="title-top" style="margin-bottom:2vh;">
        <el-button
          type="primary"
          size="small"
          @click="add"
          >添加报警设置</el-button
        >
        <!-- <el-button
          type="primary"
          size="small"
          @click="openorclose"
          >开启/关闭</el-button
        > -->
        <!-- <div class="el-title-item" style="display: inline-block">
          <el-form ref="form">
            <el-form-item label="节点编号">
              <el-input
                v-model="search"
                placeholder="请输入节点编号"
              
              ></el-input>
              <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            style="margin-left:1vw"
            @click="searching"
            >搜索</el-button
          >
            </el-form-item>
          </el-form>
        </div> -->
      </div>
      <div class="title-bottom">
        <div class="title-bottom-left">
          <!-- <el-button
            icon="el-icon-refresh-right"
            size="small"
            @click="searching"
            >刷新</el-button
          > -->
        </div>
        <!-- <div class="title-bottom-right">
          
          <el-button icon="el-icon-refresh" size="small" @click="reset"
            >重置</el-button
          >
        </div> -->
      </div>
    </div>
    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
    >
      <el-table-column
        label="序号"
        align="center"
        :index="indexMethod"
        width="100"
        type="index"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        label="设备名称"
        prop="deviceName"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="传感器名称"
        prop="sensorName"
        show-overflow-tooltip
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="表达式"
        prop="exp"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span v-if="scope.row.exp == 'gt'">大于</span>
          <span v-if="scope.row.exp == 'lt'">小于</span>
          <span v-if="scope.row.exp == 'ge'">大于等于</span>
          <span v-if="scope.row.exp == 'le'">小于等于</span>
          <span v-if="scope.row.exp == 'eq'">等于</span>
        </template>
      </el-table-column>
      <el-table-column
        label="预警值"
        prop="warnValue"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column
        label="预警次数"
        prop="warnTimes"
        show-overflow-tooltip
        align="center"
      ></el-table-column>
      <el-table-column label="预警状态" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.warnSwitch"
            :active-value="1"
            :inactive-value="0"
            active-color="#1afa29"
            inactive-color="#B9B9B9"
            @change="changeSwitch(scope.row)"
          />
        </template>
      </el-table-column>
      <!-- <el-table-column
        label="自动关闭"
        prop="data6"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.autoClose"
            :active-value="1"
            :inactive-value="0"
            active-color="#1afa29"
            inactive-color="#B9B9B9"
            @change="changeSwitch1(scope.row)"
          /> </template
      ></el-table-column> -->
      <el-table-column
        label="预警邮箱"
        prop="warnMail"
        show-overflow-tooltip
        align="center"
      >
          <template slot-scope="scope">
            <span>{{ scope.row.warnMail ? scope.row.warnMail : '暂无' }}</span>
          </template>
      </el-table-column>
      <el-table-column
        label="预警手机号"
        prop="warnTel"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.warnTel ? scope.row.warnTel : '暂无' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="预警内容"
        prop="warnMsg"
        show-overflow-tooltip
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.warnMsg ? scope.row.warnMsg : '暂无' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <!-- <el-button
            type="primary"
            size="small"
            icon="el-icon-document"
            @click="details(scope.row)"
          ></el-button> -->
          <el-button
            type="primary"
            size="small"
            icon="el-icon-edit"
            @click="edits(scope.row)"
          ></el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-delete"
            @click="deletes(scope.row.id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
        :page-sizes="[8, 10, 20, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dataTotal"
        @current-change="changePage"
        @size-change="changeSize"
      >
      </el-pagination>
    </div>

    <!-- 编辑 /  新建-->
    <el-dialog
      title=""
      :visible.sync="editss"
      width="34vw"
      class="addAlameBox"
    >
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ title }} </span>
        <span
          style="
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0px;
            font-size: 2.22vh;
          "
        >
        </span>
      </div>
      <div class="addAlaForm">
        <el-form
          ref="propsData"
          :model="propsData"
          :rules="rules"
          label-width="10vw"
        >
          <!-- <el-form-item label="名称" prop="name">
            <el-input v-model="propsData.name" :disabled="detailss"></el-input>
          </el-form-item> -->
          <!-- <el-form-item label="节点编号" prop="sensorCode">
            <el-input
              v-model="propsData.sensorCode"
              :disabled="detailss"
            ></el-input>
          </el-form-item> -->
          <el-form-item label="设备名称" prop="deviceName">
                <!-- <el-input v-model="propsData.sensorCode" :disabled="detailss"></el-input> -->
                <el-select v-model="propsData.deviceName" placeholder="请选择" @change="changeDevice" :disabled="detailss">
                  <el-option :label="item.deviceName" :value="item.id" v-for="item in deviceList" :key="item.id"></el-option>
                    <!-- <el-option v-for="(item,index) in deviceList" :key="index" :label="item.deviceName" :value="item">
                    </el-option> -->
                </el-select>
            </el-form-item>
            <el-form-item label="传感器名称" prop="sensorName">
                <!-- <el-input v-model="propsData.sensorCode" :disabled="detailss"></el-input> -->
                <el-select v-model="propsData.sensorName" placeholder="请选择" :disabled="detailss">
                  <!-- @change="changeSennor"  -->
                    <el-option v-for="item in newContent" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="表达式" prop="exp">
                <!-- <el-input v-model="propsData.sensorCode" :disabled="detailss"></el-input> -->
                <el-select v-model="propsData.exp" placeholder="请选择" :disabled="detailss">
                  <!-- @change="changeSennor" -->
                  <el-option label="大于" value="gt"></el-option>
                  <el-option label="小于" value="lt"></el-option>
                  <el-option label="大于等于" value="ge"></el-option>
                  <el-option label="小于等于" value="le"></el-option>
                  <el-option label="等于" value="eq"></el-option>
                </el-select>
            </el-form-item>
          <el-form-item label="预警值" v-show="!detailss" prop="warnValue">
            <el-input
              v-model="propsData.warnValue"
              :disabled="detailss"
            ></el-input>
          </el-form-item>
          <el-form-item label="预警次数" v-show="!detailss" prop="warnTimes">
            <el-input
              v-model="propsData.warnTimes"
              :disabled="detailss"
            ></el-input>
          </el-form-item>
          <el-form-item label="预警邮箱" v-show="!detailss" prop="warnMail">
            <el-input
              v-model="propsData.warnMail"
              :disabled="detailss"
            ></el-input>
          </el-form-item>
          <el-form-item label="预警手机号" v-show="!detailss" prop="warnTel">
            <el-input
              v-model="propsData.warnTel"
              :disabled="detailss"
            ></el-input>
          </el-form-item>
          <el-form-item label="预警内容" v-show="!detailss" prop="warnMsg">
            <el-input
              v-model="propsData.warnMsg"
              :disabled="detailss"
              type="textarea"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="推送类型" prop="type">
            <el-select v-model="propsData.type" placeholder="请选择" @change="changeMethod">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="触发表达式" prop="expression">
            <el-input
              v-model="propsData.expression"
              :disabled="detailss"
            ></el-input>
          </el-form-item> -->
          <el-form-item size="large" v-show="showFlag" class="bigBtn">
            <el-button class="cancelBtn" type="info" @click="editss = false"
              >取消</el-button
            >
            <el-button class="saveBtn" type="primary" @click="onsubmit('propsData')"
              >确认</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  /*eslint-disable */
  data() {
    return {
      valueType: '1',
      clicks:true,
      tableData: [
        {
          orderNumber: "",
          logiStatus: "",
          deliveTime: "",
          invoiceNo: "",
          people: "",
          people: "",
        },
      ],
      search: "",
      title: "",
      loading: false,
      editss: false,
      detailss: false,
      showFlag: false,
      pageSize: 30,
      pageNow: 1,
      dataTotal: 1,
      propsData: {
        deviceName:'',
        sensorName:'',
        exp:'',
        warnValue:'',
        warnSwitch:'',
        warnTimes:'',
        warnMail:'',
        warnTel:'',
        warnMsg:'',
      },
      level: "",
      rules: {
        deviceName: [{ required: true, message: "不可为空", trigger: "change" }],
        sensorName: [{ required: true, message: "不可为空", trigger: "change" }],
        exp: [{ required: true, message: "不可为空", trigger: "change" }],
        warnValue: [{ required: true, message: "不可为空", trigger: "change" }],
        warnTimes: [{ required: true, message: "不可为空", trigger: "change" }],
      },
      newFlag: false,
      options: [
        {
          value: "tel",
          label: "tel",
        },
        {
          value: "email",
          label: "email",
        },
      ],
      tips: "",
      flag: 0,
      flagWord: "open",
      deviceList: [],
      sennorList: [],
      temNameList: [],
      newContent: [],
    };
  },
  mounted() {
    this.getList();
    this.getDeviceList();
  },
  methods: {
  //  开关操作
    changeSwitch(row) {
      //console.log(row);
      this.$postJSON('/warnManage/addOrUpdateWarnSetting', row).then((res) => {
            if (res.data.state == 'success') {
              if (row.warnSwitch == 1) {
                this.$message.success("开启成功");
              } else {
                this.$message.success("关闭成功");
              }
              this.getList();
            } else {
              this.$message.error(res.data.msg)
              this.messageBox = false
            }
          })
      // this.$post("/trigger/changeState", {
      //   id: row.id,
      //   state: row.state,
      // }).then((res) => {
      //   if (res.data.state == "success") {
      //     //console.log(res);
      //     if (row.state == 1) {
      //       this.$message.success("开启成功");
      //     } else {
      //       this.$message.success("关闭成功");
      //     }
      //   }
      // });
    },
    // 获取列表
    async getList() {
      // this.loading = true;
      const { data } = await this.$get("/warnManage/getWarnSettings", {
        page: this.pageNow,
        size: this.pageSize,
        // sensorCode: this.search,
      });
      this.tableData = data.datas;
      this.dataTotal = data.count;
      this.clicks=true;
    },
    // 序号
    indexMethod(index) {
      return index + this.pageSize * (this.pageNow - 1) + 1;
    },
    // 新建
    add() {
      this.clicks=true;
      let _this = this;
      setTimeout(() => {
        _this.$refs["propsData"].clearValidate();
      }, 30);
      this.title = "新增";
      this.editss = true;
      this.detailss = false;
      this.showFlag = true;
      this.newFlag = true;
      this.propsData = {};
    },
    // 详情
    details(row) {
      this.editss = true;
      this.showFlag = false;
      this.title = "触发器信息详情";
      
      this.detailss = true;
      this.propsData = JSON.parse(JSON.stringify(row));
      let deviceNumber = JSON.parse(JSON.stringify(row)).deviceNumber;

      this.getSennorList(deviceNumber);

      let expression = JSON.parse(JSON.stringify(row)).expression;

      if (expression.indexOf('<') != -1) {
          let expressionArr = expression.split("<");
          expression = expressionArr[1];
          this.valueType = '2';
          this.propsData.expression = expression;


      } else if (expression.indexOf('>') != -1) {

          let expressionArr = expression.split(">");
          expression = expressionArr[1];
          this.valueType = '1';
          this.propsData.expression = expression;
      }
    },
    // 编辑
    edits(row) {
      this.showFlag = true;

      this.title = "编辑";
      this.editss = true;
      this.propsData = JSON.parse(JSON.stringify(row));
      this.detailss = false;
      this.newFlag = false;
    },
    // 新建 / 编辑 提交
    onsubmit(formName) {
      let newDevic = this.propsData.deviceName;
      let newDevicN = this.deviceList.find((v) => newDevic == v.id).deviceName;
      let newDevicNu = this.deviceList.find((v) => newDevic == v.id).deviceNum;
      let newSensor = this.propsData.sensorName;
      let newSensorNu;
      let newSensorN = this.newContent.find((v) => newSensor == v.id).name;;
      for (let i = 0; i < this.newContent.length; i++) {
        if (this.newContent[i].id == newSensor) {
          newSensorNu = i+1;
        }
      }
      let params = {};
      params.deviceName = newDevicN;
      params.deviceNum = newDevicNu;//少传感器相关数据
      params.sensorNum = newSensorNu;
      params.sensorName = newSensorN;
      params.exp = this.propsData.exp;
      params.warnValue = this.propsData.warnValue;
      params.warnSwitch = 1;
      params.warnTimes = this.propsData.warnTimes;
      params.warnMail = this.propsData.warnMail;
      params.warnTel = this.propsData.warnTel;
      params.warnMsg = this.propsData.warnMsg;
      params.id = this.ids;
      params.createUser = window.localStorage.getItem('user');
      let url = '/warnManage/addOrUpdateWarnSetting';
      if(this.formTitle == '新增'){
        delete params.id;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$postJSON(url, params
          ).then((res) => {
            //console.log(res);
            if (res.data.state == 'success') {
              this.$message.success(res.data.msg);
              // if(this.formTitle == '新增'){
              //   this.$message.success('添加成功')
              // }else{
              //   this.$message.success('修改成功')
              // }    
              // this.messageBox = false
              this.editss = false;
              this.getList();
            } else {
              this.$message.error(res.data.msg)
              this.messageBox = false
            }
          })
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
      
    },
    // 全部开启关闭按钮
    // async openorclose() {
    //   if (this.flag == 0) {
    //     this.tips = "是否开启全部触发器?";
    //     this.flagWord = "open";
    //     this.flag = 1;
    //   } else {
    //     this.tips = "是否关闭全部触发器?";
    //     this.flagWord = "close";
    //     this.flag = 0;
    //   }
    //   this.$confirm(this.tips, "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.$post("/trigger/toggle", {
    //         type: this.flagWord,
    //       }).then((res) => {
    //         if (res.data.state == "success") {
    //           this.$message.success(res.data.msg);
    //           this.getList();
    //         } else {
    //           this.$message.error(res.data.msg);
    //         }
    //       });
    //     })
    //     .catch((e) => e);
    // },
    
    // 重置
    reset() {
      this.search = "";
      this.level = "";
      this.level1 = "";
      this.getList();
    },
    searching() {
      this.pageNow = 1;
      this.getList();
    },
    // 删除
    // deletes(id) {
    //   // 弹出提示是否删除
    //   this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   })
    //     .then(() => {
    //       this.$post("/trigger/delTrigger", {
    //         id: id,
    //       }).then((res) => {
    //         if (res.data.state == "success") {
    //           this.$message.success("删除成功");
    //           if(this.pageSize==this.dataTotal*1-1){
    //               this.pageNow=1;
    //             }
    //           this.getList();
    //         } else {
    //           this.$message.error("删除失败");
    //         }
    //       });
    //     })
    //     .catch((e) => e);
    // },
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$delete(`/warnManage/delWarnSettingById/${id}`).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch((e) => e);
    },
    async getDeviceList() {
      const {data} = await this.$get("/deviceManage/getDeviceInfos", {
          page: 1,
          size: 10000,
      });
      this.deviceList = data.datas;

    },
    changeDevice(val) {
      let temId;
      let device  =  this.deviceList;
      for (let a = 0; a < device.length; a++) {
        if (device[a].id == val) {
          temId = device[a].deviceNum;
        }
      }
      let newTemList = [];
      this.$get(`/deviceManage/getDeviceSensor/${temId}`).then((res) => {
        let newData = res.data.data;
        let temContent = newData.templateContent.split('|');
        let temName = newData.sensorNameStr.split('|');
        for (let i = 0; i < temContent.length; i++) {
          this.newContent.push({
            id: temContent[i],
            name: temName[i],
          })
          
        }
        
      })
      
      // //console.log(this.temNameList);
      
    },
    // changeSennor(val) {
    //   //console.log(val)
    //   // this.propsData.sensorCode = val;
    //   if(val){
    //     //this.set(原数组, 索引值, 需要赋的值)
    //     this.$set(this.propsData,this.propsData.sensorCode,val)
    //   }else{
    //     this.$set(this.propsData,this.propsData.sensorCode,"")
    //   }
    // },

    // 更换每页数据
    changeSize(size) {
      this.pageSize = size;
      this.pageNow = 1;
      this.getList();
    },
    // 更换页码
    changePage(newpage) {
      this.pageNow = newpage;
      this.getList();
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped src="../../../style/commonElement.css">
</style>

<style scoped lang="less">
.newSeeds{
      padding: 4vh 2vw !important;
    box-sizing: border-box;
    height: 100%;
}
.newSeeds /deep/ .el-input__inner:hover {
  border-color: #07ca88;
}
.newSeeds /deep/ .el-input__inner:focus {
  border-color: #07ca88;
}
.roadNetwork /deep/ .el-table td.is-center,
.roadNetwork /deep/ .el-table th.is-center {
  text-align: left;
}
.roadNetwork /deep/ .el-table {
  max-height: 70vh;
  overflow-y: scroll;
}
.roadNetwork /deep/ .el-table th {
  background: #fafafa;
  box-shadow: 1px 1px 2px #fafafa;
}
.roadNetwork /deep/ .el-table th > .cell:nth-of-type(-n + 1) {
  border-right: 1px solid #ebeef5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bigBtn /deep/.el-form-item__content {
  margin-left: 0 !important;
  text-align: center;
}

.title-bottom-right {
  display: flex;
  align-items: center;
}

/deep/.el-button {
  // height: 25px;
}

.title-bottom-right/deep/.el-button--primary {
  margin-left: 10px;
}

.radios {
  display: inline-block;
}
</style>